<template>
  <div class="draw-record">
    <header>
      <div class="draw-money-details">
        <ul class="draw-title">
          <li>
            <span>当前余额(元)</span>
            <img src="@/assets/img/login/bill/noknow.png" alt="" @click="moneyTake" />
          </li>
          <!-- <li>
            <span>总金额(元)</span>
            <img
              src="@/assets/img/login/bill/noknow.png"
              alt=""
              @click="allOtherMoney"
            />
          </li> -->
        </ul>
        <ul class="draw-num">
          <li>
            <span>{{ baseData.canWithdraw }}</span>
          </li>
          <!-- <li>
            <span>{{ baseData.practicalAccount }}</span>
          </li> -->
        </ul>
        <ul class="draw-status">
          <li>
            <img src="@/assets/img/login/bill/finalldraw.png" alt="" />
            <span>全部已提：{{ baseData.widthrow }}</span>
          </li>
          <li>
            <img src="@/assets/img/login/bill/frezemoney.png" alt="" />
            <span>提现中金额：{{ baseData.frozenAmount }}</span>
          </li>
          <li>
            <img src="@/assets/img/login/bill/status.png" alt="" />
            <span>账户状态：{{ bindAccount == 1 ? "有效" : "无效" }}</span>
          </li>
        </ul>
      </div>
      <!-- 如果开启提现就显示提现，反之不显示，1 开启 2关闭 -->
      <aside class="take-money" v-if="baseData.fundState===1">
        <el-input type="number" v-model="drawNum" placeholder="请输入提现金额" class="input-draw-money"></el-input>
        <p class="sureTake" @click="sureDraw">确认提现</p>
        <ul class="draw-instrut">
          <li @click="accountInfo" style="cursor: pointer">
            到账账户信息
            <img src="@/assets/img/login/bill/more.png" alt="" class="img-more" />
          </li>
          <li>
            提现规则
            <!-- <img
              src="@/assets/img/login/bill/explore.png"
              alt=""
              class="img-explore"
            /> -->
            <i class="sx-icon-yiwen" @click="drawRule" style="cursor: pointer"></i>
          </li>
        </ul>
      </aside>
    </header>
    <!-- 绑定提现流程弹窗 -->
    <el-dialog :visible.sync="accountVisible" width="800px" center class="draw-process-dialog">
      <account-info @bindtype="bindtype" :bindStatu="bindStatus" :moneys="drawNum" :bindData="bindData" />
    </el-dialog>
    <el-dialog :visible.sync="drawVisible" width="800px" center class="draw-process-dialog">
      <draw-dialog @bindtype="bindtype" :bindStatu="bindStatus" :moneys="drawNum" :bindData="bindData" :key="drawRush" v-if="drawStatus" />
    </el-dialog>
    <el-dialog :visible.sync="bankCardVisible" width="690px" center class="bind-card">
      <bind-bank-card :type="bindTypes" :bindData="bindData" @bindtype="bindtype" :phone="phone" :key="bankRush" />
    </el-dialog>
    <el-dialog :visible.sync="checkPhoneVisible" width="690px" center class="bind-card">
      <check-phone :type="bindTypes" :bindData="bindData" @bindtype="bindtype" @rushData='rushData' :moneys="drawNum" :phone="phone"
        :key="checkRush" />
    </el-dialog>
    <Dialog ref="dialogRef"></Dialog>
    <div style="padding: 0 30px">
      <el-table stripe :data="tableData" border style="width: 100%; margin-top: 20px">
        <el-table-column prop="orderNumber" label="订单号" width="220"></el-table-column>
        <el-table-column prop="withdrawTime" label="提现时间" width="220" :formatter="formatTime">
        </el-table-column>
        <el-table-column prop="withdrawMoney" label="到账金额"></el-table-column>
        <el-table-column prop="withdrawOutTime" label="出款时间" width="220" :formatter="formatoutTime" align="center"></el-table-column>
        <el-table-column prop="withdrawState" label="提现状态" width="220">
          <template slot-scope="scope">
            <span :style="{ color: colorList[scope.row.withdrawState - 1] }"
              @click="showOrderDetail(scope.row)">{{ formatPredict(scope.row.withdrawState) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="fundState" label="提现账户状态" width="220">
          <template slot-scope="scope">
            <span v-if="scope.row.fundState===1">正常</span>
            <span v-if="scope.row.fundState===2">冻结</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="type" label="送达方式" width='100' :formatter="formatPredict"></el-table-column>
    <el-table-column prop="order_content" label="送达时间" width='100' :formatter="formatSendTime"></el-table-column> -->
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
  </div>
</template>

<script>
import Dialog from '@/components/common/dialog.vue';
import {
  $agent_fund,
  $agent_status,
  $agent_bind_type,
  $agent_withdraw_record,
  $agent_query_phone,
} from '@/api';
import drawDialog from './components/drawDialog.vue';
import bindBankCard from './components/bindBankCard.vue';
import checkPhone from './components/checkPhone.vue';
import accountInfo from './components/accountInfo.vue';

const colorList = ['#FA8828', '#333333', '#F22424'];
export default {
  components: {
    drawDialog,
    Dialog,
    bindBankCard,
    checkPhone,
    accountInfo,
  },
  data() {
    return {
      drawRush: null,
      bankRush: null,
      checkRush: null,
      allowWithdrawMoney: null,
      colorList,
      tableData: [],
      totalPage: 1,
      currentPage: 1,
      drawNum: '',
      drawVisible: false,
      accountVisible: false,
      dialogs: {},
      bankCardVisible: false,
      bindTypes: '',
      baseData: {
        amount: ' ', // 总金额
        balance: ' ', // 可用余额
        widthrow: ' ', // 累计提现金额
        frozen: '', // 冻结金额
      },
      bindAccount: '',
      bindStatus: true,
      bindData: {},
      checkPhoneVisible: false,
      phone: '',
      drawStatus: true,
    };
  },
  computed: {},
  watch: {
    drawNum(value) {
      const status = value.indexOf('.');
      if (status !== -1) {
        this.drawNum = value.slice(0, status + 3);
        console.log(this.drawNum, 'jjeee');
      }
    },
  },
  created() {
    if (this.$route.query.showAccountDialog === '1') {
      this.openDialog('accountVisible', true);
    }
    this.getWithdrawData();
  },
  mounted() { },
  // 方法集合
  methods: {
    // 限制输入
    limitNum() {
      console.log('e24');
      const status = this.drawNum.indexOf('.');
      if (status !== -1) {
        this.drawNum.slice(0, status + 3);
        console.log(this.drawNum, 'jjeee');
      }
    },
    rushData() {
      $agent_withdraw_record().then((res) => {
        this.tableData = res.withdrawLogPage.records;
        this.totalPage = Number(res.pages) || 1;
      });
      $agent_fund().then((res) => {
        this.baseData = res;
      });
    },
    // 到帐信息
    accountInfo() {
      this.openDialog('accountVisible', true);
    },
    // 时间戳处理
    formatTime({ withdrawTime }) {
      return this.$formatDate(withdrawTime, 'yyyy-MM-dd HH:mm');
    },
    formatoutTime({ withdrawOutTime }) {
      return this.$formatDate(withdrawOutTime, 'yyyy-MM-dd HH:mm') || '-';
    },
    // 分页逻辑
    pageChange() {
      $agent_withdraw_record({
        pageCurrent: this.currentPage,
        pageSize: 10,
      }).then((res) => {
        this.tableData = res.withdrawLogPage.records;
        this.totalPage = Number(res.pages) || 1;
      });
    },
    // 枚举字段
    formatPredict(status) {
      console.log(status, 'eee');
      const stateArr = ['待审核', '出款成功', '审核失败', '出款中', '出款失败'];
      return `${stateArr[status - 1]}`;
    },
    showOrderDetail(item) {
      console.log(item, 'eeee');
    },
    // 确认提现基本逻辑
    sureDraw() {
      // const rugar = /(\d{2,4})|(\d{2,4}.\d{1,2})/g;
      // if (rugar.test(this.drawNum)) {
      //   this.$message.error('输入10元到两万元');
      //   return;
      // }
      // eslint-disable-next-line eqeqeq
      if (this.bindStatus == false) {
        this.$message.error('请先绑定银行卡');
        return;
      }
      // eslint-disable-next-line eqeqeq
      if (this.drawNum == '') {
        this.$message.error('请输入提现金额');
        return;
      }
      if (+this.drawNum > 20000 || +this.drawNum < (this.allowWithdrawMoney || 1)) {
        this.$message.error(`可提现范围${this.allowWithdrawMoney || 1}元至20000元`);
        return;
      }

      const regular = /^(([1-9]{1}\d*)|(0{1}))(\.\d{0,2})?$/;
      const status = regular.test(this.drawNum);
      if (!status) {
        this.$message.error('可提现金额最多输入两位小数');
        return;
      }
      if (this.drawNum > this.baseData.canWithdraw) {
        this.$message.error('当前可提现余额不足');
        return;
      }


      this.openDialog('drawVisible', true);
    },
    // 提现基本数据
    getWithdrawData() {
      $agent_fund().then((res) => {
        console.log(res, '345');
        this.baseData = res;
        this.allowWithdrawMoney = res.allowWithdrawMoney;
      });
      $agent_status().then((res) => {
        this.bindAccount = res.state;
      });
      $agent_bind_type().then((res) => {
        // eslint-disable-next-line eqeqeq
        if (res.bankNumber == undefined) {
          this.bindStatus = false;
        } else {
          this.drawStatus = true;
          this.bindData = res;
        }
      });
      $agent_withdraw_record({
        pageCurrent: this.currentPage,
        pageSize: 10,
      }).then((res) => {
        this.tableData = res.withdrawLogPage.records;
        this.totalPage = Number(res.pages) || 1;
      });
      $agent_query_phone().then((res) => {
        this.phone = res;
      });
    },
    openDialog(item, boolean) {
      this[item] = boolean;
      if (item === 'drawVisible') {
        this.drawRush = Math.random();
      }
      if (item === 'bankCardVisible') {
        this.bankRush = Math.random();
      }
      if (item === 'checkPhoneVisible') {
        this.checkRush = Math.random();
      }
    },
    moneyTake() {
      this.$refs.dialogRef.dialogs = {
        title: '当前余额',
        content:
          '当前余额是指您帐户中的有效收益，您可以随时体现您的当前余额收益。',
        done: '已知晓',
      };
      this.$refs.dialogRef.showDialog = true;
    },
    allOtherMoney() {
      this.$refs.dialogRef.dialogs = {
        title: '总金额',
        content:
          '总金额是指您账户中，当前余额与冻结资金的总和，冻结资金将会在商户结算时添加至您的账户中。',
        done: '已知晓',
      };
      this.$refs.dialogRef.showDialog = true;
    },
    drawRule() {
      this.$refs.dialogRef.dialogs = {
        title: '提现规则',
        content:
          '您需要提供您本人的身份证信息以供出款平台做要素验证，请核实您的信息真实无误单次提现最小金额为1元。',
        done: '已知晓',
      };
      this.$refs.dialogRef.showDialog = true;
    },
    bindtype(item, boolean, type) {
      console.log(item, boolean, '4444');
      this.openDialog(item, boolean);
      this.bindTypes = type;
      if (item === 'bankCardVisible') {
        $agent_bind_type().then((res) => {
          // eslint-disable-next-line eqeqeq
          if (res.bankNumber == undefined) {
            this.bindStatus = false;
          } else {
            this.bindData = res;
            this.drawStatus = false;
            this.$nextTick(() => {
              this.drawStatus = true;
              this.bindStatus = true;
              this.bindData = res;
            });
          }
        });
      }
      if (item === 'checkPhoneVisible') {
        console.log('dfkjlskdj ');
      }
    },
  },
};
</script>
<style lang='scss' >
.draw-record {
  background: #f6f6f6;
  p {
    margin: 0;
  }
  .el-table td,
  .el-table th {
    padding: 12px 24px;
  }
  .input-draw-money {
    .el-input__inner::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    .el-input__inner::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }
  header {
    background: #ffffff;
    opacity: 1;
    border-radius: 6px;
    padding: 65px 15.7% 41px 12%;
    border-radius: 6px;
    position: relative;
    .draw-money-details {
      width: 48%;
      min-width: 440px;
    }
    .draw-title {
      display: flex;
      flex-flow: wrap;
      justify-content: space-between;
      cursor: pointer;
      img {
        width: 27px;
        height: 27px;
        vertical-align: -5px;
      }
      span {
        display: inline-block;
        height: 42px;
        font-size: 30px;
        font-weight: 400;
        line-height: 42px;
        color: #666666;
        padding-right: 5px;
      }
    }
    .draw-num {
      @extend .draw-title;
      margin-top: 20px;
      span {
        height: 70px;
        font-size: 50px;
        font-weight: bold;
        line-height: 70px;
        color: #ef3f46;
      }
    }
    .draw-status {
      margin-top: 45px;
      @extend .draw-title;
      img {
        width: 22px;
        height: 22px;
      }
      span {
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        padding-left: 10px;
      }
    }
    .take-money {
      position: absolute;
      top: 76px;
      right: 203px;
      .el-input__inner {
        border-radius: 18px;
        border: 1px solid #999999;
      }
      .sureTake {
        width: 260px;
        background: #ef3f46;
        opacity: 1;
        border-radius: 18px;
        font-size: 14px;
        font-weight: 400;
        line-height: 36px;
        color: #ffffff;
        opacity: 1;
        text-align: center;
        margin-top: 30px;
        cursor: pointer;
      }
      .draw-instrut {
        margin-top: 30px;
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        .img-more {
          width: 6px;
          height: 11px;
          vertical-align: baseline;
          padding-left: 4px;
        }
        .img-explore {
          width: 14px;
          height: 14px;
          vertical-align: text-top;
          padding-left: 4px;
          cursor: pointer;
        }
      }
    }
  }
  .draw-process-dialog {
    .el-dialog__body {
      padding: 20px 60px 60px 60px;
    }
  }
  .bind-card {
    .el-dialog__body {
      padding: 0px 45px 60px 45px;
    }
    .el-dialog--center {
      width: 702px;
      background: #ffffff;
      border: 1px solid #ececec;
      opacity: 1;
      border-radius: 6px;
    }
  }
}
</style>
