<template>
  <div class="explain-dialog-page">
    <el-dialog
      title="说明"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :show-close="false"
      center
      width="600px">
      <div class="title">为什么要预储值</div>
      <div class="content">1、进行平台性质的活动时，您需要储值后才可以使用。</div>
      <div class="content">2、保障您的支付账户资金不受其他因素影响，方便您对账。</div>
      <div class="title">什么是营销活动扣除费</div>
      <div class="content">当您向平台内用户发放平台优惠券用户领取后，需扣除您补贴的优惠金额。优</div>
      <div class="content">惠券失效或用户退款后金额自动返回余额账户。</div>
      <div class="title">预储值账户预存多少？</div>
      <div class="content">预存金额根据您活动需求发布频率而定，请尽量保持预储值账户余额大于</div>
      <div class="content">{{activiy_warn_amount}}元，以免活动无法正常进行。</div>
      <div class="title">提现规则</div>
      <div class="content">需先绑定银行卡信息，绑定成功后可操作提现</div>
      <div class="content">钱包余额均可提现，单次提现最小金额为1元</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">已知晓</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      activiy_warn_amount: 0,
    };
  },
  methods: {
    open(activiy_warn_amount) {
      this.activiy_warn_amount = activiy_warn_amount || 0;
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.explain-dialog-page {
  .title {
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0px 10px 0px;
  }
  .content {
    font-size: 14px;
    line-height: 1.5;
  }
}
</style>
