<template>
    <el-dialog
      title="提现记录"
      :visible.sync="dialogVisible"
      width="800px">
      <div class="flexbox flex-align-center">
        <div class="search-item">
          <span>提现时间</span>
          <el-date-picker
            v-model="time"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button type="info" @click="clear">清空</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%;margin-top: 20px;" border>
        <el-table-column prop="code" label="订单号" align="center"></el-table-column>
        <el-table-column prop="create_time" label="提现时间" align="center"></el-table-column>
        <el-table-column prop="out_amount" label="到账金额" align="center"></el-table-column>
        <el-table-column prop="out_time" label="出款时间" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.out_time || '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="提现状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status == '1'">待审核</span>
            <span v-else-if="scope.row.status == '2'">出款成功</span>
            <span v-else-if="scope.row.status == '3'">审核失败</span>
            <span v-else-if="scope.row.status == '4'">出款中</span>
            <span v-else-if="scope.row.status == '5'">出款失败</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        class="mt10" 
        background 
        :page-count="totalPage" 
        :current-page.sync="currentPage" 
        @current-change="withdrawList">
      </el-pagination>
    </el-dialog>
</template>
<script>
import { $sg_withdrawList } from '@/api/bill';

export default {
  data() {
    return {
      dialogVisible: false,
      time: '',
      totalPage: 1,
      currentPage: 1,
      tableData: [],
    };
  },
  methods: {
    // 打开弹窗
    open() {
      this.dialogVisible = true;
      this.search();
    },
    // 搜索
    search() {
      this.currentPage = 1;
      this.withdrawList();
    },
    // 清空搜索条件
    clear() {
      this.time = '';
    },
    // 提现列表
    withdrawList() {
      const params = {
        page: this.currentPage,
        page_size: 10,
      };
      if (this.time) {
        params.start_time = this.time[0];
        params.end_time = this.time[1];
      } else {
        params.start_time = '';
        params.end_time = '';
      }
      $sg_withdrawList(params).then((res) => {
        this.tableData = res.list;
        this.totalPage = res.total_page;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  .search-item {
    margin-right: 20px;
    & > span {
      color: #666;
      margin-right: 5px;
    }
  }
</style>
