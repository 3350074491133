<template>
  <div class="accountInfo-page">
    <el-dialog
      title="绑定提现银行卡"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      center
      width="500px">
      <div class="item">
        <span>银行卡卡号：</span>
        <el-input v-model.number="form.name" placeholder="请输入提现银行卡卡号" style="width: 240px;"></el-input>
      </div>
      <div class="item">
        <span>持卡人姓名：</span>
        <el-input v-model.number="form.name" placeholder="请输入持卡人姓名" style="width: 240px;"></el-input>
      </div>
      <div class="item">
        <span>持卡人身份证号：</span>
        <el-input v-model.number="form.name" placeholder="请输入持卡人身份证号" style="width: 240px;"></el-input>
      </div>
      <div class="item">
        <span>银行预留手机号：</span>
        <el-input v-model.number="form.name" placeholder="请输入银行预留手机号" style="width: 240px;"></el-input>
      </div>
      <el-divider></el-divider>
      <div class="tips">我们将验证您的代理商账号，以证明是您本人在操作</div>
      <div class="tips">
        验证码将发送到187****1837
        <span v-if="!sendCodeFlag" @click="sendCode">发送验证码</span>
        <span v-else>{{codeTime}}s</span>
      </div>
      <div class="input-box flexbox">
        <div class="code-item" :class="codeValue.length == 0 && inputFocus ? 'code-item-active' : ''">{{codeValue[0]}}</div>
        <div class="code-item" :class="codeValue.length == 1 && inputFocus ? 'code-item-active' : ''">{{codeValue[1]}}</div>
        <div class="code-item" :class="codeValue.length == 2 && inputFocus ? 'code-item-active' : ''">{{codeValue[2]}}</div>
        <div class="code-item" :class="codeValue.length == 3 && inputFocus ? 'code-item-active' : ''">{{codeValue[3]}}</div>
        <div class="code-item" :class="codeValue.length == 4 && inputFocus ? 'code-item-active' : ''">{{codeValue[4]}}</div>
        <div class="code-item" :class="codeValue.length >= 5 && inputFocus ? 'code-item-active' : ''">{{codeValue[5]}}</div>
        <el-input class="input-code"
          :value="codeValue"
          :maxlength="6"
          @blur="codeInputBlur"
          @focus="codeInputFocus"
          @input="codeInputChange">
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        name: '',
      },
      codeValue: '',
      inputFocus: false,
      sendCodeFlag: false,
      codeTime: 59,
    };
  },
  methods: {
    // 打开弹窗
    open() {
      this.codeValue = '';
      this.dialogVisible = true;
    },
    // 发送验证码
    sendCode() {
      this.codeTime = 59;
      this.sendCodeFlag = true;
      const timer = setInterval(() => {
        this.codeTime -= 1;
        if (this.codeTime <= 0) {
          this.sendCodeFlag = false;
          clearInterval(timer);
        }
      }, 1000);
    },
    // 验证码输入框
    codeInputChange(e) {
      if (e) {
      // 判断输入内容是否为数字
        if ((/^\+?[0-9][0-9]*$/).test(e)) {
          this.codeValue = e;
        }
      } else {
        this.codeValue = '';
      }
    },
    // 验证码输入框失去焦点
    codeInputBlur() {
      this.inputFocus = false;
    },
    // 验证码输入框获取到焦点
    codeInputFocus() {
      this.inputFocus = true;
    },
    // 确定
    async submit() {
      await this.$validator({
        ...this.form,
        codeValue: this.codeValue,
      }, [
        'name/require/银行卡卡号',
        'codeValue/require/验证码',
      ]);
      console.log(this.form);
      console.log(this.codeValue);
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.accountInfo-page {
  .item {
    width: 100%;
    margin-top: 20px;
    & > span {
      width: 120px;
      display: inline-block;
    }
  }
  .tips {
    width: 100%;
    margin-top: 20px;
    & > span {
      color: #F23026;
      margin-left: 40px;
      cursor: pointer;
      user-select: none;
    }
  }
  .input-box {
    margin-top: 20px;
    position: relative;
  }
  .input-code {
    position: absolute;
  }
  .code-item {
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    border: 1px solid #f0f0f0;
    margin-right: 10px;
  }
  .code-item-active {
    border: 1px solid #F23026;
    box-sizing: border-box;
  }
}
</style>
<style lang="scss">
.accountInfo-page {
  .input-box {
    .el-input__inner {
      width: 362px;
      height: 50px;
      background-color: transparent;
      border: none;
      color: transparent;
    }
  }
}
</style>
