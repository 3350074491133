<template>
  <div class="groupBuyingBill">
    <div class="flexbox flex-lr flex-align-center">
        <el-input v-model="form.orderId" placeholder="订单编号" style="width:200px;" clearable></el-input>
        <el-input v-model="form.shopId" placeholder="商户号" style="width:200px;" class="ml20" clearable></el-input>
        <el-input v-model="form.shopName" placeholder="商户名" style="width:200px;" class="ml20" clearable></el-input>
        <el-date-picker
          v-model="time"
          class="ml20 mr20"
          type="daterange"
          range-separator="至"
          value-format="timestamp"
          start-placeholder="下单开始时间"
          end-placeholder="下单结束时间">
        </el-date-picker>
        <el-button type="primary" @click="search">筛选</el-button>
        <el-button type="info" @click="clear">清空</el-button>
    </div>
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="orderId" label="订单编号" align="center"></el-table-column>
        <el-table-column prop="shopId" label="商户号" align="center"></el-table-column>
        <el-table-column prop="shopName" label="商户名" align="center"></el-table-column>
        <el-table-column prop="shopTel" label="商户联系方式" align="center"></el-table-column>
        <el-table-column prop="profitTime" label="分润时间" width="160" align="center"></el-table-column>
        <el-table-column prop="goodsPrice" label="商品金额" align="center"></el-table-column>
        <el-table-column prop="realPrice" label="订单金额（元）" align="center"></el-table-column>
        <el-table-column prop="orderAmount" label="订单抽佣比例" align="center">
          <template slot-scope="scope">{{scope.row.orderAmount}}%</template>
        </el-table-column>
        <el-table-column prop="scaleAmount" label="参与分润金额" align="center"></el-table-column>
        <el-table-column prop="rate" label="我的分润比例" align="center">
          <template slot-scope="scope">{{(scope.row.rate * 100).toFixed(0)}}%</template>
        </el-table-column>
        <el-table-column prop="agentAmount" label="我的分润" align="center"></el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage"
                   :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
  </div>
</template>
<script>
import { $sg_groupProfitConditions } from '@/api/groupBuying';

export default {
  data() {
    return {
      form: {
        orderId: '',
        shopId: '',
        shopName: '',
      },
      time: '',
      totalPage: 1,
      currentPage: 1,
      tableData: [],
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    // 筛选
    search() {
      this.currentPage = 1;
      this.getTableData();
    },
    // 清空
    clear() {
      this.form = {
        orderId: '',
        shopId: '',
        shopName: '',
      };
      this.time = '';
      this.currentPage = 1;
      this.getTableData();
    },
    // 请求列表数据
    getTableData() {
      const params = {
        pageCurrent: this.currentPage,
        pageSize: 15,
        ...this.form,
      };
      if (this.time) {
        const startDate = new Date(this.time[0]);
        const startYear = startDate.getFullYear();
        const startMonth = startDate.getMonth() + 1;
        const endDate = new Date(this.time[1]);
        const endYear = endDate.getFullYear();
        const endMonth = endDate.getMonth() + 1;
        if (startYear !== endYear || startMonth !== endMonth) {
          this.$message.error('查询时间必须在同一个月内');
          return;
        }
        params.startTime = this.time[0] / 1000;
        params.endTime = this.time[1] / 1000;
      } else {
        params.startTime = '';
        params.endTime = '';
      }
      $sg_groupProfitConditions(params).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
      });
    },
    // 翻页
    pageChange() {
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
.groupBuyingBill{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .tableBox{
    width: 100%;
    margin-top: 40px;
  }
  .el-table{
    min-height: 580px;
    & > th {
      background-color: #EF3F46;
      color: #fff;
    }
  }
}
</style>

