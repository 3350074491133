<template>
  <div class="walletDetails-page">
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="860px">
      <div class="dialogTitle">
        <span v-if="storeFundDetails.type == '101'">充值</span>
        <span v-else-if="storeFundDetails.type == '102'">提现成功</span>
        <span v-else-if="storeFundDetails.type == '103'">提现失败</span>
        <span v-else-if="storeFundDetails.type == '104'">提现冻结</span>
        <span v-else-if="storeFundDetails.type == '1001'">领取优惠券冻结金额</span>
        <span v-else-if="storeFundDetails.type == '1002'">优惠券失效退回金额</span>
        <span v-else-if="storeFundDetails.type == '2001'">订单消耗冻结金额</span>
        <span v-else-if="storeFundDetails.type == '2002'">退回订单消耗冻结金额</span>
        <span class="colorF63D29" v-if="storeFundDetails.symbol == '1'">+{{storeFundDetails.money}}元</span>
        <span class="color13BB79" v-else-if="storeFundDetails.symbol == '2'">-{{storeFundDetails.money}}元</span>
        <span class="color13BB79" v-else>{{storeFundDetails.money}}元</span>
      </div>
      <div class="flexbox flex-justify-between">
        <!-- 充值信息 -->
        <div class="left" v-if="storeFundDetails.type == '101'">
          <div class="title flexbox flex-align-center">
            <span class="line"></span>
            <span class="txt">账户变动信息</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>变动原因：</span>
            <span>充值</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>充值金额：</span>
            <span>{{storeFundDetails.money}}元</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>变动前余额：</span>
            <span>{{storeFundDetails.before_balance}}元</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>变动后余额：</span>
            <span>{{storeFundDetails.after_balance}}元</span>
          </div>
        </div>
        <!-- 提现信息 -->
        <div class="left" v-else-if="storeFundDetails.type == '102'
                                         || storeFundDetails.type == '103'
                                         || storeFundDetails.type == '104'">
          <div class="title flexbox flex-align-center">
            <span class="line"></span>
            <span class="txt">账户变动信息</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>变动原因：</span>
            <span>提现</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>提现金额：</span>
            <span>{{storeFundDetails.money}}元</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>变动前余额：</span>
            <span>{{storeFundDetails.before_balance}}元</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>变动后余额：</span>
            <span>{{storeFundDetails.after_balance}}元</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>变动前冻结金额：</span>
            <span>{{storeFundDetails.before_frozen}}元</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>变动后冻结金额：</span>
            <span>{{storeFundDetails.after_frozen}}元</span>
          </div>
        </div>
        <!-- 活动信息 -->
        <div class="left" v-else>
          <div class="title flexbox flex-align-center">
            <span class="line"></span>
            <span class="txt">账户变动信息</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>变动原因：</span>
            <span v-if="storeFundDetails.type == '1001'">用户领取优惠券</span>
            <span v-if="storeFundDetails.type == '1002'">优惠券失效/活动失效/活动删除</span>
            <span v-if="storeFundDetails.type == '2001'">订单消耗活动金额</span>
            <span v-if="storeFundDetails.type == '2002'">退回订单消耗活动金额</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>活动类型：</span>
            <span v-if="storeFundDetails.type == '1001' || storeFundDetails.type == '1002'">优惠券</span>
            <span v-if="storeFundDetails.type == '2001' || storeFundDetails.type == '2002'">外卖订单</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span v-if="storeFundDetails.type == '1001'">冻结金额：</span>
            <span v-if="storeFundDetails.type == '1002'">退回冻结金额：</span>
            <span v-if="storeFundDetails.type == '2001'">扣款金额：</span>
            <span v-if="storeFundDetails.type == '2002'">退回金额：</span>
            <span>{{storeFundDetails.money}}元</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between"
               v-if="storeFundDetails.type == '2001' || storeFundDetails.type == '2002'">
            <span>订单编号：</span>
            <span>{{storeFundDetails.object_id}}</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between"
               v-if="storeFundDetails.type == '1001' || storeFundDetails.type == '1002'">
            <span>变动前余额：</span>
            <span>{{storeFundDetails.before_balance}}元</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between"
               v-if="storeFundDetails.type == '1001' || storeFundDetails.type == '1002'">
            <span>变动后余额：</span>
            <span>{{storeFundDetails.after_balance}}元</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>变动前冻结金额：</span>
            <span>{{storeFundDetails.before_frozen}}元</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>变动后冻结金额：</span>
            <span>{{storeFundDetails.after_frozen}}元</span>
          </div>
        </div>
        <!-- 充值信息 -->
        <div class="right" v-if="storeFundDetails.type == '101'">
          <div class="title flexbox flex-align-center">
            <span class="line"></span>
            <span class="txt">充值信息</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>充值订单号：</span>
            <span>{{storeFundDetails.object_id}}</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>充值方式：</span>
            <span>在线充值</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>充值时间：</span>
            <span>{{storeFundDetails.create_time}}</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>充值结果：</span>
            <span>成功充值</span>
          </div>
        </div>
        <!-- 提现信息 -->
        <div class="right" v-else-if="storeFundDetails.type == '102'
                                         || storeFundDetails.type == '103'
                                         || storeFundDetails.type == '104'">
          <div class="title flexbox flex-align-center">
            <span class="line"></span>
            <span class="txt">提现信息</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>提现订单号：</span>
            <span>{{storeFundDetails.object_id}}</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>提现方式：</span>
            <span>到账银行卡</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>提现时间：</span>
            <span>{{storeFundDetails.create_time}}</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between" v-if="storeFundDetails.type == '102'">
            <span>到账时间：</span>
            <span>{{storeFundDetails.create_time}}</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>提现结果：</span>
            <span v-if="storeFundDetails.type == '102'">提现成功</span>
            <span v-if="storeFundDetails.type == '103'">提现失败</span>
            <span v-if="storeFundDetails.type == '104'">提现冻结</span>
          </div>
        </div>
        <!-- 活动信息 -->
        <div class="right" v-else-if="storeFundDetails.type == '1001' || storeFundDetails.type == '1002'">
          <div class="title flexbox flex-align-center">
            <span class="line"></span>
            <span class="txt">活动信息</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>活动编号：</span>
            <span>{{storeFundDetails.ticket_info.ticket_id}}</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>活动名称：</span>
            <span>{{storeFundDetails.activity_name}}</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>活动预算：</span>
            <span>{{storeFundDetails.activity_cost}}</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>活动周期：</span>
            <span>
              {{$formatDate(storeFundDetails.ticket_info.log_start_time, 'yyyy-MM-dd HH:mm:ss')}}
              -
              {{$formatDate(storeFundDetails.ticket_info.log_dead_time, 'yyyy-MM-dd HH:mm:ss')}}</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>创建日期：</span>
            <span>{{storeFundDetails.ticket_info.create_time}}</span>
          </div>
        </div>
        <!-- 订单信息 -->
        <div class="right" v-else-if="storeFundDetails.type == '2001' || storeFundDetails.type == '2002'">
          <div class="title flexbox flex-align-center">
            <span class="line"></span>
            <span class="txt">活动信息</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>活动类型：</span>
            <span>优惠券</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>优惠券名称：</span>
            <span>{{storeFundDetails.activity_name}}</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>优惠金额：</span>
            <span>{{storeFundDetails.ticket_info.ticket_money}}元</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>平台补贴比例：</span>
            <span>{{storeFundDetails.ticket_info.subsidy}}%</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span>平台补贴：</span>
            <span>{{storeFundDetails.money}}元</span>
          </div>
          <div class="item flexbox flex-align-center flex-justify-between">
            <span v-if="storeFundDetails.type == '2001'">补贴时间：</span>
            <span v-if="storeFundDetails.type == '2002'">退回时间：</span>
            <span>{{storeFundDetails.create_time}}</span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { $sg_storeFundDetails } from '@/api/bill';

export default {
  data() {
    return {
      dialogVisible: false,
      storeFundDetails: {},
    };
  },
  methods: {
    // 打开弹窗
    open(row) {
      this.dialogVisible = true;
      const params = {
        log_id: row.id,
      };
      $sg_storeFundDetails(params).then((res) => {
        this.storeFundDetails = res;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.walletDetails-page {
  .dialogTitle {
    width: 100%;
    font-size: 22px;
    .colorF63D29 {
      color: #F63D29;
      margin-left: 10px;
    }
    .color13BB79 {
      color: #13BB79;
      margin-left: 10px;
    }
  }
  .left, .right {
    flex: 1;
    background-color: #fff;
    margin: 5px;
    margin-top: 20px;
    padding: 15px;
    box-sizing: border-box;
    .title {
      width: 100%;
      .line {
        width: 3px;
        height: 15px;
        margin-right: 8px;
        display: inline-block;
        background-color: #F23026;
      }
      .txt {
        font-size: 15px;
        font-weight: bold;
      }
    }
    .item {
      width: 100%;
      margin-top: 15px;
      font-size: 14px;
      color: #999;
    }
  }
}
</style>
<style lang="scss">
.walletDetails-page {
  .el-dialog {
    background-color: rgb(225, 227, 230);
  }
}
</style>
