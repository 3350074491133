import { render, staticRenderFns } from "./walletDetails.vue?vue&type=template&id=3b49fecc&scoped=true&"
import script from "./walletDetails.vue?vue&type=script&lang=js&"
export * from "./walletDetails.vue?vue&type=script&lang=js&"
import style0 from "./walletDetails.vue?vue&type=style&index=0&id=3b49fecc&lang=scss&scoped=true&"
import style1 from "./walletDetails.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b49fecc",
  null
  
)

export default component.exports