import api from './api';

// 获取归属商户下拉列表
export const $sg_getShopInfoSelect = (params) => api.get('/api/agent/biz/mchGroupGoods/getShopInfoSelect', params);

// 获取团购分类下拉列表
export const $sg_getMchGroupCatSelect = (params) => api.get('/j/agent/mchGroupGoods/getMchGroupCatSelect', params);

// 新增团购
export const $sp_createGroupBuying = (params) => api.postNoReject('/api/agent/biz/mchGroupGoods/create', params);

// 编辑团购
export const $sp_upDateGroupBuying = (params) => api.postNoReject('/api/agent/biz/mchGroupGoods/update', params);

// 获取团购商品详情
export const $sg_getMchGroupGoodsDetail = (params) => api.get('/api/agent/biz/mchGroupGoods/getMchGroupGoodsDetail', params);

// 团购列表
export const $sg_queryMchGroupGoods = (params) => api.get('/api/agent/biz/mchGroupGoods/queryMchGroupGoods', params);

// 更新团购商品上下架状态
export const $sp_updateState = (params) => api.post('/api/agent/biz/mchGroupGoods/updateState', params);

// 团购分润查询
export const $sg_groupProfitConditions = (params) => api.get('/j/agent/agent/groupProfitConditions', params);

// 查询是否有用户购买
export const $sg_checkUsed = (params) => api.get('/api/agent/biz/mchGroupGoods/checkUsed', params);

// 删除操作
export const $sg_deleteGroupGoods = (params) => api.delete('/api/agent/biz/mchGroupGoods/delete', params);
