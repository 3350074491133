<template>
  <div class="share-profits-deatail">
    <el-form
      :inline="true"
      :model="formInline"
      class="demo-form-inline"
      style="width: 90%"
    >
      <el-form-item label="商户号">
        <el-input
          v-model="formInline.shopId"
          placeholder="请输入商户号"
        ></el-input>
      </el-form-item>
      <el-form-item label="商户名">
        <el-input
          v-model="formInline.shopName"
          placeholder="请输入商户名"
        ></el-input>
      </el-form-item>
      <el-form-item label="商户电话">
        <el-input
          v-model="formInline.contactTel"
          placeholder="请输入商户电话"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="订单状态">
        <el-select v-model="formInline.region" placeholder="订单状态">
          <el-option label="区域一" value="支付"></el-option>
          <el-option label="区域二" value="待支付"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="时间筛选" class="select-date">
        <el-date-picker
          @focus='selectDate'
          v-model="formInline.startTime"
          type="date"
          placeholder="选择开始日期"
          @change='changes'
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="至" class="select-date">
        <el-date-picker
          v-model="formInline.endTime"
          type="date"
          placeholder="选择结束日期"
          :picker-options="pickerOption"
          :disabled='endTimeStatus'
            @change='onChange'
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item class="search-button">
        <el-button type="primary" @click="search">查询</el-button>
      </el-form-item>
      <el-form-item class="search-button">
        <el-button type="info" @click="clearSearch">清空</el-button>
      </el-form-item>
    </el-form>
    <p class="money-explain" @click="shareExplain">
      <span>分润说明</span>
      <img src="@/assets/img/login/bill/explain.png" alt="" />
    </p>
    <el-table stripe :data="tableData" border style="width: 100%">
      <el-table-column
        prop="shopId"
        label="商户号"
        width="160"
      ></el-table-column>
      <el-table-column prop="shopName" label="商户名"> </el-table-column>
      <el-table-column
        prop="contactTel"
        label="商户联系方式"
        width="160"
      ></el-table-column>
      <el-table-column
        prop="shareProfitTime"
        label="分润时间"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="totalPrice"
        label="订单金额"
        width="160"
      ></el-table-column>
      <el-table-column
        prop="amountOfConsumption"
        label="实际支付金额"
        width="140"
      ></el-table-column>
      <el-table-column
        prop="distribution"
        label="配送费"
        width="160"
      ></el-table-column>
      <el-table-column prop="shareProfitRate" label="抽佣比例" width="160">
        <template slot-scope="scope">
          <span>{{ scope.row.shareProfitRate + "%" }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="contact_tel"
        label="平台扣除金额"
        width="180"
      ></el-table-column> -->
      <el-table-column
        prop="canShareProfit"
        label="参与分润金额"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="shareProfit"
        label="我的分润"
        width="160"
      ></el-table-column>
      <!-- <el-table-column
        prop="state"
        label="状态"
        width="160"
      ></el-table-column> -->
      <!-- <el-table-column prop="type" label="送达方式" width='100' :formatter="formatPredict"></el-table-column>
    <el-table-column prop="order_content" label="送达时间" width='100' :formatter="formatSendTime"></el-table-column> -->
    </el-table>
    <el-pagination
      class="mt10"
      background
      :page-count="totalPage"
      :current-page.sync="currentPage"
      @current-change="pageChange"
    >
    </el-pagination>
    <Dialog ref="indexDialog"></Dialog>
  </div>
</template>

<script>
import { $agent_profit } from '@/api';
// eslint-disable-next-line no-unused-vars
import Dialog from '@/components/common/dialog.vue';

const orderStateList = [
  { value: '1', name: '待接单' },
  { value: '2', name: '骑手待接单' },
  { value: '3', name: '待收货' },
  { value: '4', name: '配送完成' },
  { value: '5', name: '撤销' },
];
const date = new Date();
date.setDate(1);
date.setHours(0);
date.setSeconds(0);
date.setMinutes(0);
console.log(date, 'eeee');
export default {
  components: { Dialog },
  data() {
    return {
      formInline: {
        shopId: '',
        shopName: '',
        contactTel: '',
        startTime: '',
        endTime: '',
      },
      beginTime: date.getTime(),
      pickerOption: {
        disabledDate() {
          return false;
          // if (time.getMonth() === this.formInline.startTime.getMonth()) {
          //   return false;
          // } else {
          //   return true;
          // }
        },
      },
      orderStateList,
      startTime: '',
      endTime: '',
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      endTimeStatus: true,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getTableData();
  },
  mounted() {},
  // 方法集合
  methods: {
    // 时间框选中
    selectDate() {
      console.log(123);
    },
    // 結束时间
    onChange() {
      // this.endTimeStatus = true;
    },
    // 时间框确定
    changes() {
      this.endTimeStatus = false;
      const that = this;
      this.pickerOption = {
        disabledDate(time) {
          if (that.formInline.startTime) {
            if (time.getMonth() === that.formInline.startTime.getMonth()) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        },
      };
    },
    // 分润说明
    shareExplain() {
      this.$refs.indexDialog.dialogs = {
        icon: '/img/dialog/icon-order.png',
        title2: '分润说明',
        content2: [
          {
            title: '本月参与抽佣金额',
            list: [
              '(每笔订单金额x抽佣比例-支付手续费)x代理商分润比例=您的每笔订单',
              '收益',
            ],
          },
          {
            title: '名称定义说明',
            list: [
              '商户号：商户系统唯一编号，用于商户查询商户筛选等',
              '商户名：商户名为商户店名（大部分为缩写）',
              '商户联系电话：商户预留手机号',
              '分润时间：订单已完成后的2分钟内',
              '订单金额：用户下单时，所有收费总和',
              '实际支付金额：用户抵扣掉优惠券、积分、折扣等实际用户支付金额',
              '配送费用：第三方或商户配送商品费用',
              '抽佣比例：每笔订单平台抽取实际支付金额的比例',
              '平台扣除总金额：实际代理商可参与分润的总金额',
              '我的分润：此笔订单我的分润收益',
              '状态：分润状态',
            ],
          },
        ],
        done: '已知晓',
      };
      this.$refs.indexDialog.showDialog = true;
    },
    // 清空搜索
    clearSearch() {
      this.formInline = {
        shopId: '',
        shopName: '',
        contactTel: '',
        startTime: '',
        endTime: '',
      };
    },
    // 时间戳处理
    formatTime({ shareProfitTime }) {
      return this.$formatDate(shareProfitTime, 'yyyy-MM-dd HH:mm');
    },
    // 分页逻辑
    pageChange() {
      $agent_profit({
        pageCurrent: this.currentPage,
        startTime: this.formInline.startTime ? this.checkTime(this.formInline.startTime) : this.startTime,
        endTime: this.formInline.endTime ? this.checkTime(this.formInline.endTime) : this.endTime,
        shopId: this.formInline.shopId,
        shopName: this.formInline.shopName,
        contactTel: this.formInline.contactTel,
        pageSize: 15,
      }).then((res) => {
        this.tableData = res.list;
        this.totalPage = Number(res.pages) || 1;
      });
    },

    // 搜索查询
    search() {
      // if (this.formInline.startTime) {
      //   this.formInline.startTime = this.checkTime(this.formInline.startTime);
      // } else {
      //   this.formInline.startTime = this.startTime;
      // }
      // if (this.formInline.endTime) {
      //   this.formInline.endTime = this.checkTime(this.formInline.endTime);
      // } else {
      //   this.formInline.endTime = this.endTime;
      // }
      // eslint-disable-next-line eqeqeq
      console.log(this.formInline.endTime, this.formInline.startTime, 'status');
      // eslint-disable-next-line eqeqeq
      if (this.formInline.startTime && this.formInline.endTime == '') {
        this.$message.error('请输入结束时间');
        return;
      }
      if (this.formInline.startTime && this.formInline.endTime) {
        if (this.formInline.startTime.getTime() > this.formInline.endTime.getTime()) {
          this.$message.error('开始时间不能大于结束时间');
          return;
        }
      }
      $agent_profit({
        startTime: this.formInline.startTime ? this.checkTime(this.formInline.startTime) : this.startTime,
        endTime: this.formInline.endTime ? this.checkTime(this.formInline.endTime) : this.endTime,
        shopId: this.formInline.shopId,
        shopName: this.formInline.shopName,
        contactTel: this.formInline.contactTel,
        pageCurrent: 1,
        pageSize: 15,
      }).then((res) => {
        this.tableData = res.list;
        this.totalPage = Number(res.pages) || 1;
      });
    },
    // tabel 数据获取
    getTableData() {
      const data = new Date();
      const year = data.getFullYear();
      let month = data.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      let d = data.getDate();
      d = d < 10 ? `0${d}` : d;
      this.startTime = `${year}-${month}-01`;
      this.endTime = `${year}-${month}-${d}`;
      $agent_profit({
        pageCurrent: this.currentPage,
        pageSize: 15,
        startTime: this.startTime,
        endTime: this.endTime,
      }).then((res) => {
        this.tableData = res.list;
        this.totalPage = Number(res.pages) || 1;
      });
    },
    checkTime(time) {
      const year = time.getFullYear();
      let month = time.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      let d = time.getDate();
      d = d < 10 ? `0${d}` : d;
      return `${year}-${month}-${d}`;
    },
  },
};
</script>
<style lang='scss' >
.share-profits-deatail {
  .money-explain {
    width: 100%;
    margin-top: 20px;
    text-align: right;
    color: #f22424;
    cursor: pointer;
    span {
      padding-right: 5px;
    }
    img {
      vertical-align: middle;
    }
  }
  padding: 40px 20px;
  background: #ffffff;
  border-radius: 6px;
  .el-input__inner {
    height: 32px !important;
    line-height: 32px !important;
  }
  .el-form-item__label {
    line-height: 36px;
  }
  .el-form-item__content {
    line-height: 32px;
    width: 160px;
  }
  .el-form-item {
    margin-right: 50px;
  }
  .demo-form-inline {
    //   width: 80px;
  }
  .select-date {
    margin-right: 10px !important;
    .el-input {
      width: 160px !important;
    }
  }
  .search-button {
    margin-left: 10px;
    margin-right: 10px !important;
    .el-button {
      width: 80px;
    }
    .el-form-item__content {
      width: 80px;
    }
  }
}
</style>
