<template>
  <div class="operatingWallet-page">
    <div class="header-box flexbox flex-justify-between">
      <div class="header-left">
        <div class="money-box flexbox">
          <div class="money-item">
            <div>{{storeFundAmountInfo.balance || 0}}元</div>
            <div>钱包余额</div>
          </div>
          <div class="money-item">
            <div>{{storeFundAmountInfo.frozen || 0}}元</div>
            <div>冻结金额</div>
          </div>
        </div>
        <div class="money-tips">
          <span>请尽量保持账户余额在{{storeFundAmountInfo.activiy_warn_amount || 0}}元以上，否则可能影响您创建营销活动。</span>
          <span @click="openWarningSetting">预警设置</span>
        </div>
      </div>
      <div class="header-right flexbox">
        <div class="money-btn-box">
          <div class="money-btn" @click="openrechargeDialog('1')">充值</div>
          <div class="money-btn" @click="openrechargeDialog('2')">提现</div>
          <div class="withdraw-box flexbox flex-justify-between flex-align-center">
            <!-- <div @click="openAccountInfo">
              <span>到账账户信息</span>
              <i class="el-icon-arrow-right"></i>
            </div> -->
            <div @click="openWithdrawListDialog">
              <span>提现记录</span>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
        <div class="explain" @click="openExplainDialog">
          <span>说明</span>
          <i class="el-icon-question"></i>
        </div>
      </div>
    </div>
    <div class="search-box flexbox flex-align-center flex-wrap">
      <div class="search-item">
        <span>选择类型</span>
        <el-select v-model="form.type" clearable style="width:200px;">
          <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="search-item">
        <span>筛选时间</span>
        <el-date-picker
          v-model="time"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <div class="search-item">
        <span>流水号</span>
        <el-input v-model="form.order_id" placeholder="请输入流水号" style="width:200px;" clearable></el-input>
      </div>
      <div class="search-item">
        <el-button type="primary" @click="search">搜索</el-button>
      </div>
      <div class="search-item">
        <el-button type="info" @click="clear">清空</el-button>
      </div>
    </div>
    <div class="table-box">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="create_time" label="日期" align="center" width="200"></el-table-column>
        <el-table-column prop="id" label="流水号" align="center"></el-table-column>
        <el-table-column prop="type" label="类型" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.type == '101'">充值</span>
            <span v-else-if="scope.row.type == '102'">提现成功</span>
            <span v-else-if="scope.row.type == '103'">提现失败</span>
            <span v-else-if="scope.row.type == '104'">提现冻结</span>
            <span v-else-if="scope.row.type == '1001'">领取优惠券冻结金额</span>
            <span v-else-if="scope.row.type == '1002'">优惠券失效退回金额</span>
            <span v-else-if="scope.row.type == '2001'">订单消耗冻结金额</span>
            <span v-else-if="scope.row.type == '2002'">退回订单消耗冻结金额</span>
          </template>
        </el-table-column>
        <el-table-column prop="money" label="发生金额" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.symbol == '1'">+{{scope.row.money}}</span>
            <span v-else-if="scope.row.symbol == '2'">-{{scope.row.money}}</span>
            <span v-else>{{scope.row.money}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="after_balance" label="余额" align="center"></el-table-column>
        <el-table-column prop="after_frozen" label="冻结金额" align="center"></el-table-column>
        <el-table-column prop="" label="详情" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="viewDetails(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        class="mt10" 
        background 
        :page-count="totalPage" 
        :current-page.sync="currentPage" 
        @current-change="getTableData">
      </el-pagination>
    </div>
    <!-- 说明弹窗 -->
    <ExplainDialog ref="explainDialog" />
    <!-- 账户预警设置弹窗 -->
    <WarningSetting ref="warningSetting" />
    <!-- 到账账户信息 -->
    <AccountInfo ref="accountInfo" />
    <!-- 提现记录 -->
    <WithdrawList ref="withdrawList" />
    <!-- 充值和提现弹窗 -->
    <Recharge ref="recharge" @storeFundAmount="storeFundAmount"/>
    <!-- 详情 -->
    <WalletDetails ref="walletDetails" />
  </div>
</template>
<script>
import { $sg_storeFundList, $sg_storeFundAmount } from '@/api/bill';
import ExplainDialog from './components/explainDialog.vue';
import WarningSetting from './components/warningSetting.vue';
import AccountInfo from './components/accountInfo.vue';
import WithdrawList from './components/withdrawList.vue';
import Recharge from './components/recharge&withdraw.vue';
import WalletDetails from './components/walletDetails.vue';

export default {
  components: {
    ExplainDialog,
    WarningSetting,
    AccountInfo,
    WithdrawList,
    Recharge,
    WalletDetails,
  },
  data() {
    return {
      form: {
        order_id: '',
        type: '',
      },
      time: '',
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      typeOptions: [
        {
          value: '',
          label: '全部类型',
        },
        {
          value: '101',
          label: '充值',
        },
        {
          value: '1001',
          label: '领取优惠券冻结金额',
        },
        {
          value: '1002',
          label: '优惠券失效退回金额',
        },
        {
          value: '2001',
          label: '订单消耗冻结金额',
        },
        {
          value: '2002',
          label: '退回订单消耗冻结金额',
        },
        {
          value: '102',
          label: '提现成功',
        },
        {
          value: '104',
          label: '提现冻结',
        },
        {
          value: '103',
          label: '提现失败',
        },
      ],
      storeFundAmountInfo: {},
    };
  },
  created() {
    this.storeFundAmount();
    this.search();
  },
  methods: {
    // 查看账户金额
    storeFundAmount() {
      $sg_storeFundAmount().then((res) => {
        this.storeFundAmountInfo = res;
      });
    },
    // 筛选
    search() {
      this.currentPage = 1;
      this.getTableData();
    },
    // 清空
    clear() {
      Object.keys(this.form).forEach((val) => {
        this.form[val] = '';
      });
      this.time = '';
      this.search();
    },
    // 请求列表数据
    getTableData() {
      const params = {
        page: this.currentPage,
        size: 10,
        ...this.form,
      };
      if (this.time) {
        params.start_time = this.time[0];
        params.end_time = this.time[1];
      } else {
        params.start_time = '';
        params.end_time = '';
      }
      $sg_storeFundList(params).then((res) => {
        this.tableData = res.list;
        this.totalPage = res.total_page;
      });
    },
    // 查看详情
    viewDetails(row) {
      this.$refs.walletDetails.open(row);
    },
    // 账户预警设置
    openWarningSetting() {
      this.$refs.warningSetting.open();
    },
    // 打开充值&提现弹窗
    openrechargeDialog(type) {
      // type: '1'-充值，'2'-提现
      this.$refs.recharge.open(type);
    },
    // 打开到账账户信息弹窗
    openAccountInfo() {
      this.$refs.accountInfo.open();
    },
    // 提现记录
    openWithdrawListDialog() {
      this.$refs.withdrawList.open();
    },
    // 打开说明弹窗
    openExplainDialog() {
      this.$refs.explainDialog.open(this.storeFundAmountInfo.activiy_warn_amount);
    },
  },
};
</script>
<style lang="scss" scoped>
.operatingWallet-page {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  .header-box {
    width: 100%;
    height: 160px;
    background-color: #F23026;
    border-radius: 12px;
    color: #fff;
    .money-item {
      margin: 30px 30px 20px 30px;
      font-size: 24px;
      & > div {
        text-align: center;
      }
      & > div:last-child {
        font-size: 20px;
      }
    }
    .money-tips {
      padding-left: 30px;
      box-sizing: border-box;
      & > span:last-child {
        border-bottom: 1px solid #fff;
        user-select: none;
        cursor: pointer;
      }
    }
    .money-btn {
      width: 175px;
      height: 40px;
      border-radius: 12px;
      background-color: #fff;
      color: #F23026;
      font-size: 14px;
      margin-top: 15px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
      user-select: none;
    }
    .withdraw-box {
      margin-top: 10px;
      font-size: 12px;
      width: 175px;
      margin-right: 40px;
      & > div {
        cursor: pointer;
        user-select: none;
      }
    }
    .explain {
      margin: 15px 15px 0px 0px;
      cursor: pointer;
      user-select: none;
    }
  }
  .search-box {
    width: 100%;
    .search-item {
      margin-right: 20px;
      margin-top: 30px;
      & > span {
        color: #666;
        margin-right: 5px;
      }
    }
  }
  .table-box {
    width: 100%;
    margin-top: 30px;
  }
}
</style>
