<template>
  <div class="warningSetting-page">
    <el-dialog
      title="账户预警设置"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :show-close="false"
      center
      width="600px">
      <div class="item">
        <span>账户余额低于</span>
        <el-input v-model.number="form.account_warn_amount" placeholder="请输入" style="width: 140px;margin: 0px 5px;"></el-input>
        <span>元，短信提示</span>
      </div>
      <div class="item">
        <span>账户余额低于</span>
        <el-input v-model.number="form.activiy_warn_amount" placeholder="请输入" style="width: 140px;margin: 0px 5px;"></el-input>
        <span>元，系统自动暂停“进行中的活动”</span>
      </div>
      <div class="tips">注：活动自动暂停后，充值完成后需代理商手动启用</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submit">保存并生效</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { $sp_withdrawWarning, $sg_withdrawAccount } from '@/api/bill';

export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        account_warn_amount: '',
        activiy_warn_amount: '',
      },
    };
  },
  methods: {
    // 打开弹窗
    open() {
      this.dialogVisible = true;
      $sg_withdrawAccount().then((res) => {
        this.form.account_warn_amount = res.account_warn_amount || '';
        this.form.activiy_warn_amount = res.activiy_warn_amount || '';
      });
    },
    // 提交
    async submit() {
      await this.$validator({
        ...this.form,
      }, [
        'account_warn_amount/require/账户余额',
        'activiy_warn_amount/require/账户余额',
      ]);
      if (+this.form.account_warn_amount === 0 || +this.form.activiy_warn_amount === 0) {
        this.$message.error('账户余额必须大于0');
        return;
      }
      const params = {
        ...this.form,
      };
      $sp_withdrawWarning(params).then(() => {
        this.dialogVisible = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.warningSetting-page {
  .item {
    width: 100%;
    margin-top: 20px;
  }
  .tips {
    width: 100%;
    margin-top: 10px;
    color: #F23026;
  }
}
</style>
