<template>
  <div class="draw-dialog">
    <p class="draw-dialog-title">提现金额（元）</p>
    <p class="draw-dialog-num">{{this.moneys}}</p>
    <p class="draw-account-info">到账账户信息</p>
    <ul class="draw-type" v-if="!bindStatus">
      <li>
        <p class="draw-type-title">
          <img
            src="@/assets/img/login/bill/bankCard.png"
            alt=""
            style="vertical-align: baseline"
          /><span style="padding-left: 6px">银行卡</span>
        </p>
        <p class="draw-type-page">
          <span class="draw-type-desc">还未绑定提现银行卡，</span
          ><span
            class="draw-type-fnc"
            @click="openDialog('bankCardVisible', true,'bank')"
            >立即绑定</span
          ><img
            src="@/assets/img/login/bill/more.png"
            alt=""
            class="draw-type-img"
          />
        </p>
      </li>
      <!-- <li>
        <p class="draw-type-title">
          <img
            src="@/assets/img/login/bill/zhifubao.png"
            alt=""
            style="vertical-align: middle"
          /><span style="padding-left: 6px">支付宝</span>
        </p>
        <p class="draw-type-page">
          <span class="draw-type-desc">还未绑定提现支付宝，</span
          ><span class="draw-type-fnc"   @click="openDialog('bankCardVisible', true,'pay')">立即绑定</span
          ><img
            src="@/assets/img/login/bill/more.png"
            alt=""
            class="draw-type-img"
          />
        </p>
      </li> -->
    </ul>
    <ul class="draw-type" v-if="bindStatus">
      <li class="item" @click="changeCss(1)" :class="{ active: checked }">
        <img
          src="@/assets/img/login/bill/checked.png"
          alt=""
          class="check-img"
          v-if="checked"
        />
        <img
          src="@/assets/img/login/bill/checkWhite.png"
          alt=""
          v-if="!checked"
          class="check-img"
        />
        <p class="draw-type-title">
          <img
            src="@/assets/img/login/bill/bankCard.png"
            alt=""
            style="vertical-align: baseline"
            v-if="!checked"
          />
          <img
            src="@/assets/img/login/bill/activeCard.png"
            alt=""
            style="vertical-align: baseline"
            v-if="checked"
          />
          <span style="padding-left: 6px">银行卡</span>
        </p>
        <p class="draw-type-pages">{{toThousands(bindData.accountIdCard)||''}}</p>
        <p class="draw-type-person">
          <span>{{this.bindData.accountName}}</span>
          <span>{{this.bindData.phone}}</span>
        </p>
      </li>
      <!-- <li class="item" @click="changeCss(2)" :class="{ active: !checked }">
        <img
          src="@/assets/img/login/bill/checked.png"
          alt=""
          class="check-img"
          v-if="!checked"
        />
        <img
          src="@/assets/img/login/bill/checkWhite.png"
          alt=""
          v-if="checked"
          class="check-img"
        />
        <p class="draw-type-title">
          <img
            src="@/assets/img/login/bill/zhifubao.png"
            alt=""
            style="vertical-align: middle"
            v-if="checked"
          />
          <img
            src="@/assets/img/login/bill/activeZhi.png"
            alt=""
            style="vertical-align: middle"
            v-if="!checked"
          />
          <span style="padding-left: 6px">支付宝</span>
        </p>
        <p class="draw-type-pages">6217 0038 1000 8732 298</p>
        <p class="draw-type-person">
          <span>王子健</span>
          <span>中国建设银行</span>
        </p>
      </li> -->
    </ul>
    <aside class="edit-info" v-if="bindStatus">
      <p>
        <span  @click="openDialog('bankCardVisible', true,'bank')">修改信息</span
        ><img
          src="@/assets/img/login/bill/more.png"
          alt=""
          class="draw-type-img"
        />
      </p>
      <!-- <p>
        <span>修改信息</span
        ><img
          src="@/assets/img/login/bill/more.png"
          alt=""
          class="draw-type-img"
        />
      </p> -->
    </aside>
    <footer class="draw-foot" @click.stop="takeMoney">
      {{ bindStatus ? "确定" : "请先绑定提现信息" }}
    </footer>
  
  </div>
</template>

<script>


export default {
  components: { },
  data() {
    return {
      bindStatus: this.bindStatu,
      checked: true,
      bankCardVisible: false,
    };
  },
  props: {
    bindStatu: Boolean,
    moneys: String,
    bindData: Object,
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  // 方法集合
  methods: {
    toThousands(num) {
      let result = ''; let 
        counter = 0;
      // eslint-disable-next-line no-param-reassign
      num = (num || 0).toString();
      // eslint-disable-next-line no-plusplus
      for (let i = num.length - 1; i >= 0; --i) {
        // eslint-disable-next-line no-plusplus
        counter++;
        result = num.charAt(i) + result;
        if (!(counter % 4) && i !== 0) { result = ` ${result}`; }
      }
      return result;
    },
    // 提现
    takeMoney() {
      if (this.bindStatus) {
        this.$emit('bindtype', 'checkPhoneVisible', true); 
      } else {
        this.$message.error('请先绑定请先绑定银行卡信息');
      }
    },
    changeCss(type) {
      if (type === 1) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
    openDialog(item, boolean) {
      this.$emit('bindtype', item, boolean);
    },
  },
};
</script>
<style lang='scss' >
.draw-dialog {
  .el-dialog {
    width: 702px;
    height: 692px;
    background: #ffffff;
    border: 1px solid #ececec;
    border-radius: 6px !important;
  }
  >>>.el-dialog__body {
    padding: 0 30px;
  }
  .edit-info {
    padding-top: 12px;
    display: flex;
    flex-flow: wrap;
    justify-content: space-around;
    p {
      text-align: right;
      width: 312px;
      cursor: pointer;
    }
    span {
      padding-right: 6px;
    }
    img {
      height: 11px;
      width: 6px;
      vertical-align: -1px;
    }
  }
  .draw-dialog-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
  }
  .draw-dialog-num {
    font-size: 40px;
    font-weight: bold;
    line-height: 56px;
    color: #ef3f46;
    padding-top: 12px;
  }
  .draw-account-info {
    margin-top: 34px;
    font-size: 16px;
    color: #666666;
  }
  .draw-type {
    margin-top: 16px;
    display: flex;
    flex-flow: wrap;
    justify-content: space-around;

    li {
      width: 310px;
      padding: 16px 0 35px 0;
      border: 1px solid #ececec;
      border-radius: 6px;
    }
    .active {
      color: #333333 !important;
      background: rgba(239, 63, 70, 0.05) !important;
    }
    .item {
      width: 310px;
      padding: 16px 0 16px 0;
      border-radius: 6px;
      position: relative;
      color: #666666;
      cursor: pointer;
      .check-img {
        position: absolute;
        top: 0;
      }
    }
    .draw-type-title {
      color: #666666;
      text-align: center;
    }
    .draw-type-desc {
      font-size: 14px;
      color: #999999;
    }
    .draw-type-fnc {
      color: #ef3f46;
      padding-right: 4px;
      cursor: pointer;
    }
    .draw-type-img {
      height: 11px;
      width: 6px;
      vertical-align: -1px;
    }
    .draw-type-page {
      text-align: center;
      margin-top: 30px;
    }
    .draw-type-pages {
      margin-top: 20px;
      text-align: center;

      font-size: 16px;
    }
    .draw-type-person {
      display: flex;
      flex-flow: wrap;
      justify-content: space-between;
      padding: 7px 53px 0 53px;
      font-size: 16px;

      line-height: 22px;
    }
  }
  .draw-foot {
    width: 260px;
    background: #ef3f46;
    opacity: 1;
    border-radius: 6px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 36px;
    color: #ffffff;
    opacity: 1;
    text-align: center;
    margin: 60px auto 0 auto;
     cursor: pointer;
  }
}
</style>
