<template>
  <div class="bind-bank-card">
    <p class="bind-card-title">绑定提现银行卡</p>
    <aside class="el-form-div">
      <el-form
        :model="bankForm"
        :rules="rules"
        ref="bankForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="银行卡卡号：" prop="bankCardId">
          <el-input
            v-model="bankForm.sourceBankNumber"
            type="number"
            name="bankCardId"
            placeholder="请输入提现银行卡卡号"
            class="card-input"
          ></el-input>
        </el-form-item>
        <el-form-item label="持卡人姓名：" prop="bankName">
          <el-input
            v-model="bankForm.accountName"
            placeholder="请输入持卡人姓名"
          ></el-input>
        </el-form-item>
        
        <el-form-item label="持卡人身份证号：" prop="idCard">
          <el-input
            v-model="bankForm.accountIdCard"
            placeholder="请输入持卡人身份证号"
            type="text"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行预留手机号：" prop="bankPhone">
          <el-input
            v-model="bankForm.phone"
            placeholder="请输入银行预留卡号"
            type="number"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item>
    <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
    <el-button @click="resetForm('ruleForm')">重置</el-button>
  </el-form-item> -->
      </el-form>
    </aside>
    <div class="form-line"></div>
    <p class="validation-account">
      我们将验证您的代理商账号，以证明是您本人在操作
    </p>
    <p class="send-message">验证码将发送到{{ phones }}</p>
    <p class="send-message-code">
      <span class="send-title" @click.stop="sendMessage" v-if="!codeStatus"
        >发送验证码</span
      ><span class="send-time" v-if="codeStatus">{{ time }}s后可再次发送</span>
    </p>
    <!-- 验证码输入框 -->
    <div class="input-box">
      <label class="simple-input-content" for="simpleInput1">
        <span class="highlight">{{ simpleInput1.slice(0, 1) }}</span>
        <span :class="simpleInput1.length > 1 ? 'highlight' : ''">{{
          simpleInput1.slice(1, 2)
        }}</span>
        <span :class="simpleInput1.length > 2 ? 'highlight' : ''">{{
          simpleInput1.slice(2, 3)
        }}</span>
        <span :class="simpleInput1.length > 3 ? 'highlight' : ''">{{
          simpleInput1.slice(3, 4)
        }}</span>
        <span :class="simpleInput1.length > 4 ? 'highlight' : ''">{{
          simpleInput1.slice(4, 5)
        }}</span>
        <span :class="simpleInput1.length > 5 ? 'highlight' : ''">{{
          simpleInput1.slice(5, 6)
        }}</span>
      </label>
      <el-input
        id="simpleInput1"
        v-model.trim="simpleInput1"
        type="number"
        oninput="if(value.length>6)value=value.slice(0,6)"
        style="opacity: 0"
      />
    </div>
    <footer @click="submitForm('bankForm')" :class="{ active: active }">
      确定
    </footer>
  </div>
</template>

<script>
import { sendTelCode } from '@/global/globalMethods';
import { $agent_sumbit_account } from '@/api';

export default {
  components: {},
  data() {
    return {
      bankForm: this.bindData,
      active: false,
      rules: {
     
      },
      simpleInput0: '',
      simpleInput1: '',
      input0: '',
      input1: '',
      input2: '',
      input3: '',
      input4: '',
      input5: '',
      max: 6,
      time: 0,
      codeStatus: false,
      phones: '',
      codeSendding: false,
    };
  },
  props: {
    type: String,
    bindData: Object,
    phone: String,
  },
  computed: {},
  watch: {
    simpleInput1(value) {
      if (value.length === 6) {
        this.active = true;
      } else {
        this.active = false;
      }
    },
  },
  created() {
    this.phones = `${this.phone.slice(0, 3)}****${this.phone.slice(7, 11)}`;
  },
  mounted() {},
  // 方法集合
  methods: {
    // 发送验证码
    sendMessage() {
      console.log('123');
      if (this.codeSendding) return;
      this.codeSendding = true;
      sendTelCode(this.phone, 'agent')
        .then(() => {
          setTimeout(() => {
            this.codeSendding = false;
          }, 1500);
          this.codeStatus = true;
          this.time = 120;
          const time = setInterval(() => {
            this.time -= 1;
            if (this.time === 0) {
              clearInterval(time);
              this.codeStatus = false;
            }
          }, 1000);
        })
        .catch(() => {
          setTimeout(() => {
            this.codeSendding = false;
          }, 1500);
        })
        .finally(() => {
        
        });
    },
    // 提交代理信息修改
    submitForm(formName) {
      const rugularBackCard = /^\d{16,19}$/g;
      const nameRegular = /.{1,20}/;
      if (!nameRegular.test(this.bankForm.accountName)) {
        this.$message.error('请输入持卡人姓名');
        return;
      }
      
      const BackCardState = rugularBackCard.test(this.bankForm.sourceBankNumber);
      if (!BackCardState) {
        this.$message.error('请输入正确的银行卡号');
        return;
      }
      const regularIdCard = /^[0-9xX]{18}$/g;
      const state = regularIdCard.test(this.bankForm.accountIdCard);
      if (!state) {
        this.$message.error('请输入正确的身份证号');
        return;
      }
      const rugularPhone = /^\d{10,11}$/g;
      const PhoneState = rugularPhone.test(this.bankForm.phone);
      if (!PhoneState) {
        this.$message.error('请输入正确电话号码');
        return;
      }
      // eslint-disable-next-line consistent-return
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const submitdata = {
            accountIdCard: this.bankForm.accountIdCard,
            bankCardId: this.bankForm.sourceBankNumber,
            bankName: this.bankForm.accountName,
            appName: 'agent',
            messageCode: this.simpleInput1,
            phone: this.phone,
            idCard: this.bankForm.accountIdCard,
            bankPhone: this.bankForm.phone,
          };
          $agent_sumbit_account(submitdata).then(() => {
            this.$emit('bindtype', 'bankCardVisible', false);
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 验证码
    // deleteValue(inputValue, previousItem) {

    //   console.log(this[inputValue], this[previousItem]);
    //   if (this[inputValue].length > 0) {

    //     this[inputValue] = '';
    //   } else {

    //     this.$nextTick(() => {
    //       this.$refs[previousItem].focus();
    //       this[previousItem] = '';
    //       this[inputValue] = '';
    //     });
    //   }
    // },
    // changeValue(e, inputValue, nextItem) {
    //   console.log(e.keyCode, this[inputValue], this[nextItem]);
    //   if (e.keyCode !== 8) {
    //     this.$nextTick(() => {
    //       this.$refs[nextItem].focus();
    //       this[inputValue] = this[inputValue].toString().slice(-1);
    //     });
    //   }
    // },
  },
};
</script>
<style lang='scss' >
.bind-bank-card {
  .card-input {
    .el-input__inner::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    .el-input__inner::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }
  footer {
    width: 260px;
    background: #ef3f46;
    opacity: 1;
    border-radius: 6px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 36px;
    color: #ffffff;
    opacity: 1;
    text-align: center;
    margin: 0px auto 0 auto;
    cursor: pointer;
    pointer-events: none;
  }
  .active {
    background: #ef3f46;
    pointer-events: auto;
  }
  .validation-account {
    padding: 40px 0 0 65px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    opacity: 1;
  }
  .send-message {
    display: inline-block;
    padding: 20px 0 0 65px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    opacity: 1;
  }
  .send-message-code {
    display: inline-block;
    padding: 20px 0 0 67px;
    .send-title {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 20px;
      color: #ef3f46;
      opacity: 1;
      cursor: pointer;
    }
    .send-time {
      padding-left: 4px;
      font-weight: 400;
      line-height: 20px;
      color: #999999;
      opacity: 1;
    }
  }
  .bind-card-title {
    text-align: center;
    padding-top: 8px;
    font-size: 22px;
    font-weight: bold;
    line-height: 30px;
    color: #333333;
    opacity: 1;
  }
  .el-form-div {
    width: 480px;
    margin: 30px 0px 40px 60px;
  }
  .el-form-item__label {
    width: 140px !important;
  }
  .el-input {
    width: 85%;
  }
  .el-form-item__error {
    width: 95%;
    text-align: right;
  }
  .el-form-item__label {
    text-align: left;
  }
  .form-line {
    width: 612px;
    height: 1px;
    background: #cccccc;
    opacity: 1;
    border-radius: 1px;
  }

  .input-box {
    width: 100%;
    display: inline-block;
    padding: 28px 20px 20px 20px;
    box-sizing: border-box;
    input {
      vertical-align: middle;
    }
    & + .input-box {
      margin-top: 20px;
    }
    // 六个span时的样式
    .simple-input-content {
      label {
        padding: 20px;
      }
      span {
        vertical-align: middle;
        line-height: 54px;
        text-align: center;
        font-size: 24px;
        border: 1px solid #ececec;
        // border: 1px solid #ef3f46;
        display: inline-block;
        height: 54px;
        width: 54px;
        margin-right: 24px;
        &.highlight {
          border-color: #ef3f46;
        }
      }
    }
    // 六个input时的样式
    .input-content {
      padding: 20px;
      input {
        width: 20px;
        height: 20px;
        text-align: center;
      }
    }
    /* 去掉input[type=number]浏览器默认的icon显示 */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      // chrome
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
    }
    input {
      // 火狐
      -moz-appearance: textfield;
    }
  }
}
</style>
