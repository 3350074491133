<template>
  <div class="recharge-page">
    <el-dialog
      :title="type == '1' ? '充值' : '提现'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      center
      width="500px">
      <div class="item">
        <span v-if="type == '1'">充值金额</span>
        <span v-if="type == '2'">提现金额</span>
        <el-input v-model="money" type="number" placeholder="请输入金额" style="width: 140px;margin: 0px 5px;"></el-input>
        <span>元</span>
      </div>
      <div class="account">当前账户余额：{{storeFundAmountInfo.balance}}元</div>
      <div class="tips" v-if="type == '1'">
        <span>预计充值后余额：</span>
        <span v-if="money">{{ parseInt((+storeFundAmountInfo.balance + (+money)) * 100) / 100}}</span>
        <span v-else>{{storeFundAmountInfo.balance}}</span>
        <span>元</span>
        </div>
      <div class="tips" v-if="type == '2'">可提现金额：{{storeFundAmountInfo.balance}}元，提现中金额：{{storeFundAmountInfo.withdraw_frozen}}元</div>
      <div class="tips" v-if="type == '2'">
        <span>到账账户：</span>
        <span v-if="storeFundAmountInfo.bank_card_id">{{storeFundAmountInfo.bank_card_id}}</span>
        <el-button v-else type="primary" @click="goBandAccount">去绑定</el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-if="type == '1'" @click="openPayDialog">立即充值</el-button>
        <el-button type="primary" v-if="type == '2'" @click="withdraw">确认提现</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
    <!-- 支付弹窗 -->
    <el-dialog
      title="扫码支付"
      :visible.sync="payDialog"
      :close-on-click-modal="false"
      center
      width="375px">
      <div class="pay-num">充值金额：{{money}}元</div>
      <img class="pay-code-img" :src="qrCodeUrl" alt="">
      <div class="pay-tips-title">请打开手机微信扫码支付</div>
      <div class="pay-tips">注：支付后无法退款，请知悉</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="payDialogBtn">我已支付</el-button>
      </span>
    </el-dialog>
    <!-- 结果提示弹窗 -->
    <el-dialog
      :title="resultObj.dialogTitle"
      :visible.sync="resultDialog"
      :close-on-click-modal="false"
      center
      width="325px">
      <img class="result-img" v-if="resultObj.img" :src="resultObj.img" alt="">
      <div class="result-tips-title" v-if="resultObj.tipsTitle">{{resultObj.tipsTitle}}</div>
      <div class="result-tips" v-if="resultObj.tipsContent">{{resultObj.tipsContent}}</div>
      <span slot="footer" class="dialog-footer" v-if="resultObj.btn">
        <el-button type="primary" class="result-btn" @click="closeResultDialog">{{resultObj.btn}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  $sp_createRechargeOrder,
  $sg_queryPayState,
  $sp_withdrawApply,
  $sg_withdrawAccount,
} from '@/api/bill';
import { getQrcodeSrc } from '@/global/globalMethods';

export default {
  data() {
    return {
      type: '', // 1-充值，2-提现
      storeFundAmountInfo: {},
      dialogVisible: false,
      money: '',
      payDialog: false,
      resultDialog: false,
      resultObj: {
        dialogTitle: '',
        img: '',
        tipsTitle: '',
        tipsContent: '',
        btn: '',
      },
      order_id: '', // 充值订单id
      qrCodeUrl: '', // 充值二维码
      payResult: false, // 是否充值/提现成功
    };
  },
  methods: {
    // 打开充值&支付弹窗
    open(type) {
      this.type = type;
      this.money = '';
      this.dialogVisible = true;
      $sg_withdrawAccount().then((res) => {
        this.storeFundAmountInfo = res;
        this.storeFundAmountInfo.balance = res.balance || 0;
      });
    },
    // 打开支付弹窗
    openPayDialog() {
      if (!this.money && this.money !== 0) {
        this.$message.error('充值金额不能为空');
        return;
      }
      if (!/^(([1-9]{1}\d*)|(0{1}))(\.\d{0,2})?$/.test(this.money)) {
        this.$message.error('充值金额最多输入两位小数');
        return;
      }
      if (+this.money < 10) {
        this.$message.error('最低10元起充');
        return;
      }
      if (+this.money > 999999.99) {
        this.$message.error('充值金额最大可输入999999.99');
        return;
      }
      const params = {
        money: this.money,
      };
      $sp_createRechargeOrder(params).then((res) => {
        this.qrCodeUrl = getQrcodeSrc(res);
        this.order_id = res.split('=')[1];
        this.payDialog = true;
      });
    },
    // 我已支付
    payDialogBtn() {
      // 查询订单是否已支付
      const params = {
        order_id: this.order_id,
      };
      $sg_queryPayState(params).then((res) => {
        if (res.is_pay) {
          this.resultObj = {
            dialogTitle: '支付成功',
            img: '/img/dialog/icon-success.png',
            tipsTitle: '',
            tipsContent: '充值金额已即时到账，请查看',
            btn: '好的',
          };
        } else {
          this.resultObj = {
            dialogTitle: '',
            img: '/img/dialog/exploreIcon.png',
            tipsTitle: '提示',
            tipsContent: '抱歉，系统未检测到充值~',
            btn: '好的',
          };
        }
        this.payResult = res.is_pay;
        this.payDialog = false;
        this.resultDialog = true;
      });
    },
    // 提现
    withdraw() {
      if (!this.money && this.money !== 0) {
        this.$message.error('提现金额不能为空');
        return;
      }
      if (!/^(([1-9]{1}\d*)|(0{1}))(\.\d{0,2})?$/.test(this.money)) {
        this.$message.error('提现金额最多输入两位小数');
        return;
      }
      if (+this.money < 1) {
        this.$message.error('最低提现金额为1元');
        return;
      }
      if (+this.money > 999999.99) {
        this.$message.error('提现金额最大可输入999999.99');
        return;
      }
      const params = {
        money: this.money,
      };
      $sp_withdrawApply(params).then(() => {
        this.resultObj = {
          dialogTitle: '提示',
          img: '/img/dialog/icon-success.png',
          tipsTitle: '',
          tipsContent: '提现申请已提交成功，审核通过后自动到账。在提现记录查看审核状态',
          btn: '好的',
        };
        this.payResult = true;
        this.dialogVisible = false;
        this.resultDialog = true;
      });
    },
    // 关闭结果提示弹窗
    closeResultDialog() {
      this.resultDialog = false;
      if (this.payResult) {
        this.dialogVisible = false;
        this.$emit('storeFundAmount');
      }
    },
    // 去绑定
    goBandAccount() {
      this.$router.push({
        name: 'WithdrawalRecord',
        query: {
          showAccountDialog: '1',
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.recharge-page {
  .item {
    width: 100%;
    margin-top: 20px;
    font-size: 18px;
  }
  .account {
    width: 100%;
    margin-top: 20px;
    font-size: 18px;
  }
  .tips {
    width: 100%;
    margin-top: 20px;
    font-size: 14px;
  }
  .pay-num {
    width: 100%;
    margin-top: 20px;
    color: #F23026;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
  .pay-code-img {
    width: 210px;
    height: 210px;
    display: block;
    margin: 0 auto;
    margin-top: 20px;
  }
  .pay-tips-title {
    width: 100%;
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
  }
  .pay-tips {
    width: 100%;
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
  }
  .result-img {
    width: 120px;
    height: 120px;
    display: block;
    margin: 0 auto;
  }
  .result-tips-title {
    width: 100%;
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
  }
  .result-tips {
    width: 100%;
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
  }
  .result-btn {
    margin-top: 60px;
    width: 200px;
  }
}
</style>
