<template>
  <div class="share-profits-deatail">
    <el-form :inline="true" :model="formInline" class="demo-form-inline" style="width: 100%">
      <!-- <el-form-item label="归属分公司">
        <el-select v-model="formInline.spreadId" placeholder="请选择分公司" clearable filterable remote :loading="loading"
          :remote-method="searchBranchOffice" @focus="searchBranchOffice('')">
          <el-option v-for="item in branchOfficeList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>

      </el-form-item> -->
      <!-- <el-form-item label="代理商类型">
        <el-select v-model="formInline.rootAgentType" placeholder="代理商类型" clearable filterable>
          <el-option :label="item.name" :value="item.id" v-for="(item,index) in rootAgentTypeList" :key='index'></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="普通代理商等级">
        <el-select v-model="formInline.level" placeholder="代理商等级" clearable filterable>
          <el-option label="V0" value="0"></el-option>
          <el-option label="V1" value="1"></el-option>
          <el-option label="V2" value="2"></el-option>
          <el-option label="V3" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="代理商名称">
        <el-input v-model="formInline.agentName" placeholder="代理商名称" clearable></el-input>
      </el-form-item>
      <el-form-item label="姓名">
        <el-input v-model="formInline.realName" placeholder="姓名" clearable></el-input>
      </el-form-item>
      <el-form-item label="手机号">
        <el-input v-model="formInline.phone" placeholder="手机号" clearable></el-input>
      </el-form-item>
      <el-form-item class="search-button">
        <el-button type="primary" @click="search">查询</el-button>
      </el-form-item>
      <el-form-item class="search-button">
        <el-button type="info" @click="clearSearch">清空</el-button>
      </el-form-item>
    </el-form>
    <!-- <el-button type="primary" @click="addAgent" style="float:right;margin-bottom:10px;">新增代理商</el-button> -->
    <el-table stripe :data="tableData" border style="width: 100%">
      <el-table-column prop="createTime" label="创建时间" width="180">
        <template slot-scope="scope"><span>{{$formatDate(scope.row.createTime, 'yyyy-MM-dd HH:mm:ss')}}</span></template>
      </el-table-column>
      <el-table-column prop="agentSid" label="代理商ID"> </el-table-column>
      <el-table-column prop="spreadName" label="归属分公司"> </el-table-column>
      <el-table-column prop="agentName" label="代理商名称" width="180"></el-table-column>
      <el-table-column prop="rootAgentType" label="代理商类型" width="160">
        <template slot-scope="scope">
          <template>
            <span>{{rootAgentTypeCheck(scope.row)}}</span>
          </template>

        </template>
      </el-table-column>
      <el-table-column prop="recommendAgentName" label="推荐代理" width="120">
        <template slot-scope="scope">
          <span>{{scope.row.recommendAgentName?scope.row.recommendAgentName:'--'}}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="vipLevel"
        label="代理商等级"
        width="120"
      >
       <template slot-scope="scope">
        <span>{{(scope.row.vipLevel||scope.row.vipLevel==0)?('v'+scope.row.vipLevel):'--'}}</span>
      </template>
      </el-table-column> -->
      <el-table-column prop="phone" label="手机号" width="180"></el-table-column>

      <el-table-column prop="realName" label="真实姓名" width="140"></el-table-column>
      <el-table-column prop="directAgentCount" label="直推下级" width="120">
        <template slot-scope="scope">
          {{scope.row.directAgentCount||0}}
        </template>
      </el-table-column>
      <el-table-column prop="shopCount" label="直推商户数量" width="120">
        <template slot-scope="scope">
          {{scope.row.shopCount||0}}
        </template>
      </el-table-column>
      <el-table-column prop="directTakeoutCount" label="直推商户订单数量" width="120">
        <template slot-scope="scope">
          {{scope.row.directTakeoutCount||0}}
        </template>
      </el-table-column>
      <el-table-column prop="rate" label="分润比例" width="120">
        <template slot-scope="scope">
          <span> {{scope.row.rate?((scope.row.rate*100).toFixed(2)*1)+'%':'0%'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="balance" label="可提现金额" width="120">
        <template slot-scope="scope">
          {{scope.row.balance||0}}
        </template>
      </el-table-column>
      <el-table-column prop="state" label="启用状态" width="120" align="center">
        <template slot-scope="scope">
          <span :style="{color:scope.row.state==1?'green':'#f22424'}">{{ scope.row.state==1?'启用':'禁用' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="" label="提现账户状态" width="120" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.fundState===1">正常</span>
          <span v-if="scope.row.fundState===2">冻结</span>
          <span v-if="!scope.row.fundState">-</span>
        </template>
      </el-table-column>
      <el-table-column :render-header="openTips" prop="" label="开启提现" width="120" align="center">
        <template slot-scope="scope">
          <!-- eslint-disable-next-line vue/valid-v-model -->
          <el-switch v-if="scope.row.fundState" v-model="scope.row.fundState" :active-value="1" :inactive-value="2" disabled
            @click.native="fundStateDialog(scope.row)">
          </el-switch>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column prop="state" label="操作" width="200" fixed="right">
        <template slot-scope="scope">
          <div>
            <span :class="{topactive:((scope.row.preAgentCount>0)&&(deep!=0))}" @click="lookTopLevel(scope.row)">查看上级</span>
            <el-divider direction="vertical"></el-divider>
            <span :class="{bottomactive:scope.row.subAgentCount>0}" @click="lookBotLevel(scope.row)">查看下级</span>
            <el-divider direction="vertical"></el-divider>
            <!-- <span style="color: #f22424;cursor: pointer;"
            :class="{editActive:deep>=1}"
              @click="editAgent(scope.row)">编辑</span> -->
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
    <!-- 新增编辑代理商 -->
    <el-dialog :title="agentAddOrEdit" :visible.sync="dialogFormVisible" width=" 630px" class='agentDialog'>
      <el-form :model="form">
        <el-form-item label="代理名称" :label-width="formLabelWidth" :label-position="position">
          <el-input v-model="addAgentForm.agentName" autocomplete="off" placeholder="请输入代理名称"></el-input>
        </el-form-item>
        <el-form-item label="姓名" :label-width="formLabelWidth" :label-position="position">
          <el-input v-model="addAgentForm.realName" autocomplete="off" placeholder="请输入用户姓名"></el-input>
        </el-form-item>
        <el-form-item label="登录账号" :label-width="formLabelWidth" :label-position="position">
          <el-input v-model="addAgentForm.phone" autocomplete="off" placeholder="请输入用户手机号作为登录手机号"></el-input>
        </el-form-item>
        <el-form-item label="账号密码" :label-width="formLabelWidth" :label-position="position">
          <el-input v-model="addAgentForm.password" autocomplete="off" placeholder="请输入密码"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureSubmit">确 定</el-button>
      </div>
    </el-dialog>
    <Dialog ref="changeFundStateTips" dialogWidth="500px">
      <div class="dialogBtn flexbox flex-align-center flex-justify-center">
        <el-button class="btn" type="primary" @click="confirmChange">确认</el-button>
        <el-button class="btn" type="info" @click="$refs.changeFundStateTips.showDialog = false">取消</el-button>
      </div>
    </Dialog>
    <Dialog ref="tipsDialog"></Dialog>
  </div>
</template>

<script>
import {
  $agent_selectAgent, $agent_insertAgent, $agent_updateByAgentSid, $sp_spreadInfoList, $agent_selectPreAgentPlat, $sp_changeAgentFundState,
} from '@/api/agentAll';
// eslint-disable-next-line no-unused-vars
import Dialog from '@/components/common/dialog.vue';

const rootAgentTypeList = [{ name: '普通代理商', id: 0 }, { id: 1, name: '代理商' }, { id: 2, name: '服务商' }, { id: 3, name: '联合创始人' }];
const orderStateList = [
  { value: '1', name: '待接单' },
  { value: '2', name: '骑手待接单' },
  { value: '3', name: '待收货' },
  { value: '4', name: '配送完成' },
  { value: '5', name: '撤销' },
];
const date = new Date();
date.setDate(1);
date.setHours(0);
date.setSeconds(0);
date.setMinutes(0);
console.log(date, 'eeee');
export default {

  data() {
    return {
      ChosenFundState: 1, // 选中的账户的提现状态
      chosenAgentSid: '', // 选中的账户的id
      deep: 0,
      position: 'left',
      rootAgentTypeList,
      formLabelWidth: '100px',
      dialogFormVisible: false,
      loading: false,
      formInline: {
        spreadId: '',
        level: '',
        agentName: '',
        rootAgentType: '',
        phone: '',
        realName: '',
      },
      form: {},
      orderStateList,
      startTime: '',
      endTime: '',
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      endTimeStatus: true,
      agentAddOrEdit: '编辑代理商',
      branchOfficeList: [],
      addAgentForm: {
        agentName: '',
        realName: '',
        phone: '',
        password: '',
      },
    };
  },
  computed: {},
  components: {
    Dialog,
  },
  watch: {},
  created() {
    this.getTableData({
      pageCurrent: 1,
      pageSize: 10,
    });
    this.searchBranchOffice('');
  },

  mounted() { },
  // 方法集合
  methods: {
    // 表头点击展开弹窗
    openTips() {
      return (<div>
        <span>开启提现</span>
        <img class="question" src="/img/index/question_w.png" alt="" onClick={() => this.showTips()} />
      </div>);
    },
    // 打开提示弹窗
    showTips() {
      this.$refs.tipsDialog.dialogs = {
        icon: '/img/dialog/exploreIcon.png',
        title: '说明',
        content: '关闭提现功能是关闭下级代理商的提现账户，只能对直推代理商进行关闭操作，关闭后此代理商将无法进行提现操作。请与下级代理沟通一致后再进行操作。',
        done: '已知晓',
      };
      this.$refs.tipsDialog.showDialog = true;
    },
    // 提现状态弹窗
    fundStateDialog(row) {
      let title = '';
      let content = '';
      this.chosenAgentSid = row.agentSid;
      this.ChosenFundState = row.fundState;
      if (this.ChosenFundState === 2) {
        title = '说明';
        content = '开启该代理的提现账户以后，该代理商将可以直接进行提现操作，确认要开启代理商的提现和账户吗？';
      } else {
        title = '提示';
        content = '关闭该代理的提现账户以后，该代理商将无法手动进行提现操作，确认要关闭此代理商的提现账户吗？';
      }
      this.$refs.changeFundStateTips.dialogs = {
        icon: '/img/dialog/exploreIcon.png',
        title,
        content,
      };
      this.$refs.changeFundStateTips.showDialog = true;
    },
    confirmChange() {
      console.log(this.ChosenFundState, '修改之前的值');
      if (this.ChosenFundState === 2) {
        // eslint-disable-next-line no-unused-expressions
        this.ChosenFundState = 1;
      } else {
        // eslint-disable-next-line no-unused-expressions
        this.ChosenFundState = 2;
      }
      const params = {
        agentSid: this.chosenAgentSid,
        fundState: this.ChosenFundState,
      };
      console.log(params, '修改之后的值');
      $sp_changeAgentFundState(params).then(() => {
        this.getTableData({
          pageCurrent: this.currentPage,
          pageSize: 10,
        });
      }).catch(() => {
      });
      this.$refs.changeFundStateTips.showDialog = false;
    },
    // 改变代理商提现账户状态
    changeFundState(e, agentSid) {
      console.log(e, agentSid);
      // let title = '';
      // let content = '';
      // if (e === 1) {
      //   title = '说明';
      //   content = '开启该代理的提现账户以后，该代理商将可以直接进行提现操作，确认要开启代理商的提现和账户吗？';
      // } else {
      //   title = '提示';
      //   content = '关闭该代理的提现账户以后，该代理商将无法手动进行提现操作，确认要关闭此代理商的提现账户吗？';
      // }
      // this.$refs.changeFundStateTips.dialogs = {
      //   icon: '/img/dialog/icon-man.png',
      //   title,
      //   content,
      // };
      // this.$refs.changeFundStateTips.showDialog = true;
      // const params = {
      //   agentSid,
      //   fundState: e,
      // };
      // $sp_changeAgentFundState(params).then(() => {

      // }).catch(() => {

      // });
    },
    // 处理代理商等级
    rootAgentTypeCheck(item) {
      if (item.rootAgentType) {
        return this.rootAgentTypeList[item.rootAgentType].name;
        // eslint-disable-next-line eqeqeq
      } else if (item.rootAgentType == 0) {
        return this.rootAgentTypeList[item.rootAgentType].name;
      } else {
        return '--';
      }
    },
    lookTopLevel(item) {
      if (item.preAgentCount > 0 && this.deep !== 0) {
        $agent_selectPreAgentPlat({
          pageCurrent: 1,
          pageSize: 10,
          agentSid: item.agentSid,
        }).then((res) => {
          this.deep -= 1;

          this.tableData = res.records || [];
          this.tableData.forEach((op) => {
            Object.assign(op, op.agentV2StatisticsVo);
          });
          this.totalPage = Number(res.pages) || 1;
        });
      }
    },
    async lookBotLevel(item) {
      console.log(item, 'dfdsf');
      if (item.subAgentCount > 0) {
        await this.getTableData({
          pageCurrent: 1,
          pageSize: 10,
          agentSid: item.agentSid,
        });
        this.deep += 1;
        console.log(this.deep, '34332');
      }
    },
    levelFormat(item) {
      // eslint-disable-next-line eqeqeq
      if (item == '0') {
        return 'V0';
      }
      // eslint-disable-next-line eqeqeq
      if (item && item != 0) {
        return `V${item}`;
      } else {
        return '--';
      }
    },
    // 归属员工查询
    async searchBranchOffice(query) {
      this.loading = true;
      if (query) {
        await this.reviewedShopPage(query);
      } else {
        await this.reviewedShopPage('');
      }
      this.loading = false;
    },
    // 请求归属分公司数据
    reviewedShopPage(platName = '') {
      const params = {
        platName,
        pageSize: 200,
      };
      return $sp_spreadInfoList(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.spreadId,
            label: item.platName,
          };
          tempArr.push(json);
        });
        console.log(tempArr, '3345');
        this.branchOfficeList = tempArr;
      });
    },
    editAgent(item) {
      // eslint-disable-next-line eqeqeq
      if (this.deep < 1) {
        this.addAgentForm.agentSid = item.agentSid;
        this.addAgentForm.realName = item.realName;
        this.addAgentForm.agentName = item.agentName;
        this.addAgentForm.phone = item.phone;
        this.addAgentForm.password = item.password;
        this.dialogFormVisible = true;
        this.agentAddOrEdit = '编辑代理商';
      }
    },
    addAgent() {
      this.addAgentForm = {
        agentName: '',
        realName: '',
        phone: '',
        password: '',
      };
      this.dialogFormVisible = true;
      this.agentAddOrEdit = '新增代理商';
    },
    sureSubmit() {
      const addAgentRegular = /^.{1,20}$/;
      const shopAgentRegular = /^.{2,14}$/;
      const phoneRegular = /^\d{1,11}$/;
      const pswRegular = /^\w{6,20}$/;
      if (!shopAgentRegular.test(this.addAgentForm.agentName)) {
        this.$message.error('请输入代理商名称,2-14个字以内');
        return;
      }
      if (!addAgentRegular.test(this.addAgentForm.realName)) {
        this.$message.error('请输入用户姓名,1-20个字以内');
        return;
      }
      if (!phoneRegular.test(this.addAgentForm.phone)) {
        this.$message.error('请输入正确的用户手机号');
        return;
      }
      if (this.addAgentForm.password) {
        if (!pswRegular.test(this.addAgentForm.password)) {
          this.$message.error('请输入密码,6-20个字符以内');
          return;
        }
      }

      if (this.agentAddOrEdit === '编辑代理商') {
        $agent_updateByAgentSid(this.addAgentForm).then(() => {
          this.dialogFormVisible = false;
          this.getTableData({
            pageCurrent: this.currentPage,
            pageSize: 10,
          });
        });
      } else {
        $agent_insertAgent(this.addAgentForm).then(() => {
          this.dialogFormVisible = false;
          this.getTableData({
            pageCurrent: this.currentPage,
            pageSize: 10,
          });
        });
      }
    },
    // 时间框选中
    selectDate() {
      console.log(123);
    },
    // 結束时间
    onChange() {
      // this.endTimeStatus = true;
    },
    // 时间框确定
    changes() {
      this.endTimeStatus = false;
      const that = this;
      this.pickerOption = {
        disabledDate(time) {
          if (that.formInline.startTime) {
            if (time.getMonth() === that.formInline.startTime.getMonth()) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        },
      };
    },

    // 清空搜索
    clearSearch() {
      this.currentPage = 1;
      this.formInline = {
        spreadId: '',
        level: '',
        agentName: '',
        rootAgentType: '',
        phone: '',
        realName: '',
      };
      this.getTableData({
        pageCurrent: 1,
        pageSize: 10,
      });
    },
    // 时间戳处理
    formatTime({ shareProfitTime }) {
      return this.$formatDate(shareProfitTime, 'yyyy-MM-dd HH:mm');
    },
    // 分页逻辑
    pageChange() {
      $agent_selectAgent({
        ...this.formInline,
        pageCurrent: this.currentPage,
        pageSize: 10,
      }).then((res) => {
        this.tableData = res.records || [];
        this.totalPage = Number(res.pages) || 1;
      });
    },

    // 搜索查询
    search() {
      this.getTableData({
        pageCurrent: 1,
        pageSize: 10,
        ...this.formInline,
      });
    },
    // tabel 数据获取
    getTableData(param) {
      $agent_selectAgent(param).then((res) => {
        console.log(res.records, '获取到的数据');
        this.tableData = res.records || [{}];
        this.tableData.forEach((item) => {
          Object.assign(item, item.agentV2StatisticsVo);
        });
        this.totalPage = Number(res.pages) || 1;
      });
    },

  },
};
</script>
<style lang='scss' >
.share-profits-deatail {
  .el-switch.is-disabled .el-switch__core,
  .el-switch.is-disabled .el-switch__label {
    cursor: pointer;
  }
  .topactive {
    color: #f22424;
    cursor: pointer;
  }
  .bottomactive {
    color: #f22424;
    cursor: pointer;
  }
  .editActive {
    color: #666666 !important;
    cursor: unset !important;
  }
  .money-explain {
    width: 100%;
    margin-top: 20px;
    text-align: right;
    color: #f22424;
    cursor: pointer;
    span {
      padding-right: 5px;
    }
    img {
      vertical-align: middle;
    }
  }
  padding: 40px 20px;
  background: #ffffff;
  border-radius: 6px;
  .demo-form-inline {
    .el-input__inner {
      height: 32px !important;
      line-height: 32px !important;
    }
    .el-form-item__label {
      line-height: 36px;
    }
    .el-form-item__content {
      line-height: 32px;
      width: 130px;
    }
    .el-form-item {
      margin-right: 30px;
    }
  }
  .select-date {
    margin-right: 10px !important;
    .el-input {
      width: 160px !important;
    }
  }
  .search-button {
    margin-left: 10px;
    margin-right: 10px !important;
    .el-button {
      width: 80px;
    }
    .el-form-item__content {
      width: 80px;
    }
  }
  .agentDialog {
    .el-dialog__body {
      padding: 20px 100px 20px 80px;
    }
  }
  .dialogBtn {
    width: 100%;
    margin-top: 60px;
    .btn {
      width: 200px;
      height: 50px;
      border-radius: 50px;
    }
  }
  .question {
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
</style>
